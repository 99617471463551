@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700&display=swap');

* {
  font-family: "Raleway", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-feature-settings: 'pnum' on, 'lnum' on;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
*::-webkit-scrollbar {
  display: none;
}

body {
   background: #fafafa !important;
}

.title{
  font-size: 35px;
  font-weight: 500;
}

@media (max-width: 992px){
  .title{
    font-size: 24px;
  }
}

:root {
  /* -------------------------------------------------------------------------- */
  /*                                   Colors                                   */
  /* -------------------------------------------------------------------------- */
  --primary: #F8BD30;
  --primary-light:#FFF5DD;
  --primary-mid: #FFD87A;
  --primary-dark: #E2AA01;
  --secondary: #00A8FF;
  --secondary-light: #E6F6FF;
  --tertiary: #A153DF;
  --tertiary-light:#F5E8FF;
  --black: #1B1B1B;
  --white: #fff;
  --gray-1: #333;
  --gray-2: #4f4f4f;
  --gray-3: #727272;
  --gray-4: #9d9d9d;
  --gray-5: #c8c8c8;
  --gray-6: #eaeaea;
  --gray-7: #fafafa;
  --green: #2EC04F;
  --green-light: #d9fcd8;
  --warning: #E3412D;
  --warning-light: #FFDDDA;
  --warning-dark: #6A1A10;
  --success: var(--green);
  --success-light: var(--green-light);

  /* -------------------------------------------------------------------------- */
  /*                                 Font Styles                                */
  /* -------------------------------------------------------------------------- */
  /* Font Size 12 */
  --fs-12-medium: normal normal 500 12px/16px "Raleway", serif;
  --fs-12-semibold: normal normal 600 12px/16px "Raleway", serif;
  /* Font Size 14 */
  --fs-14-medium: normal normal 500 14px/20px "Raleway", serif;
  --fs-14-semibold: normal normal 600 14px/20px "Raleway", serif;
  --fs-14-bold: normal normal 700 14px/20px "Raleway", serif;
  /* Font Size 16 */
  --fs-16-medium: normal normal 500 16px/24px "Raleway", serif;
  --fs-16-semibold: normal normal 600 16px/24px "Raleway", serif;
  --fs-16-bold: normal normal 700 16px/24px "Raleway", serif;
  /* Font Size 20 */
  --fs-20-medium: normal normal 500 20px/24px "Raleway", serif;
  --fs-20-semibold: normal normal 600 20px/24px "Raleway", serif;
  --fs-20-bold: normal normal 700 20px/24px "Raleway", serif;
  /* Font Size 24 */
  --fs-24-medium: normal normal 500 24px/28px "Raleway", serif;
  --fs-24-semibold: normal normal 600 24px/28px "Raleway", serif;
  --fs-24-bold: normal normal 700 24px/28px "Raleway", serif;
  /* Font Size 32 */
  --fs-32-medium: normal normal 500 32px/28px "Raleway", serif;
  --fs-32-semibold: normal normal 600 32px/28px "Raleway", serif;
  --fs-32-bold: normal normal 700 32px/28px "Raleway", serif;
}
p {
  margin: 0;
  padding: 0;
}
.form-label{
  font-weight: 500;
}
.text-align-right {
  text-align: right;
}

.br-5 {
  border-top-left-radius: 5%;
  border-top-right-radius: 5%;
}

.generalBtn {
  background-color: var(--black) !important;
  width: 100%;
  text-decoration:none;
  padding:12px 25px;
  border-radius:4px;
  color: white;
  cursor:pointer;
}
.generalBtn:hover{
  background-color: var(--gray-3) !important;
  color: white;
}
.generalBtn:focus{
  border:0px;
}

.blueBtn {
  background-color: var(--secondary-light) !important;
  color: var(--secondary);
  width: auto;
  text-decoration:none;
  padding:12px 25px;
  margin-right:12px;
  border-radius:4px;
  cursor:pointer;
  border:0;
}
.blueBtn:hover{
  background-color: var(--secondary) !important;
  color: white;
}

.successBtn {
  background-color: var(--success) !important;
  width: 100%;
  text-decoration:none;
  padding:12px 25px;
  border-radius:4px;
  color: white;
  cursor:pointer;
}
.successBtn:hover{
  background-color: var(--secondary) !important;
  color: white;
  text-decoration:none;
}


td{
  vertical-align: middle;
}