/* Back Button */
.backButtonContainer{
    display:flex; 
    margin-bottom:0px;
}
.backButton{
    font-weight: 500;
    margin-left: 8px;
    cursor:pointer;
}

/* General */
.orderContainer{
    margin: 25px auto 35px auto;
    background-color: #fff;
    border: 1px solid #eaeaea;
    padding:35px;
    border-radius: 8px;
}
.orderBodySection{
    padding: 28px 0px;
    border-bottom: 1px solid #eaeaea;
    display: flex;
}
.orderBodySection:last-of-type{
    border-bottom: 0px;
}
.orderHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
}


/* Icon Styles - General */
.orderSectionIcon{
    margin-right:16px;
    height:45px;
    width:45px;
    border-radius:4px;
    background-color:var(--primary-light);
    display: flex;
    justify-content: center;
    align-items: center;
}
.orderSectionIcon img{
    height:24px;
    width:24px;
}

.deshabilitar{
    background-color: var(--warning) !important;
    border:0;
    margin-top: 12px;
}
.habilitar{
    background-color: var(--success) !important;
    border:0;
    margin-top: 12px;
}

/* Icon Styles - Colors */
.primary{
    background-color:var(--primary-light);
    color:var(--primary-dark);
}
.primary img{
    filter: invert(90%) sepia(13%) saturate(4069%) hue-rotate(333deg) brightness(101%) contrast(94%);
}
.secondary{
    background-color:var(--secondary-light);
    color:var(--secondary-dark);
}
.secondary img{
    filter: invert(41%) sepia(100%) saturate(1577%) hue-rotate(175deg) brightness(105%) contrast(104%);
}
.tertiary{
    background-color:var(--tertiary-light);
    color:var(--tertiary-dark);
}
.tertiary img{
    filter: invert(48%) sepia(42%) saturate(7226%) hue-rotate(250deg) brightness(94%) contrast(86%);
}
.success{
    background-color: rgb(233, 255, 248);
}
.success img{
    filter: invert(64%) sepia(63%) saturate(435%) hue-rotate(111deg) brightness(88%) contrast(85%);
}


/* Order content */
.orderSectionContent{
    width:100%;
}
.orderSectionContent p{
    padding:4px 0px;
}
.orderSectionContent input{
    padding: 12px 16px;
    background: #F4F4F4;
    border-radius: 8px;
    border:0px;
    width:100%;
}

.cardUser{
    padding: 12px 20px;
    border-radius: 8px;
    border: 1px solid rgba(128, 128, 128, 0.322);
    margin-bottom: 12px;
}
.cardUser p{
    margin:0;
}

.statusContainer{
    display:flex;
    align-items: center;
    cursor:pointer;
    position:relative;
}
.status{
    display:flex;
    align-items: center;
    padding:12px 25px;
    border-radius:4px;
    margin-right: 12px;
}
.status-1{
    background-color:var(--primary-light) !important;
    color:var(--primary-dark) !important;
}
.status-2{
    background-color:var(--success-light) !important;
    color:var(--success) !important;
}
.status-3{
    background-color:var(--warning-light) !important;
    color:var(--warning-dark) !important;
}


.deshabilitar{
    background-color: var(--warning) !important;
    border:0;
    margin-top: 12px;
}
.habilitar{
    background-color: var(--success) !important;
    border:0;
    margin-top: 12px;
}

.orderStatusDropdown{
    position:absolute;
    bottom:-155px;
    right:0;
    width:100%;
    z-index: 1000;
    background-color: white;
    border: 1px solid #eaeaea;
    border-radius:4px;
}
.orderStatusDropdown p{
    background-color: white;
    padding:12px;
    border-bottom: 1px solid #eaeaea;
    border-radius:0px;
    margin:0;
}
.orderStatusDropdown p:hover{
    background-color: var(--gray-6);
    border-radius:0px;
}
.orderStatusDropdown p:last-of-type{
    border-bottom: 0px;
}


@media (max-width: 992px){
    .orderContainer{
        padding:16px 24px;
    }
}