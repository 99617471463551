.orderHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
}
.button{
    margin-right:8px;
    background-color: #E2AA01;
    border: 0;
}