.referenceItem{
    display:flex;
    align-items: center;
    margin-bottom:12px;
}

.referenceDot{
    width:20px;
    height:20px;
    border-radius: 50%;
    margin-right: 8px;
}

.referenceLabel{
    color: var(--grey-3);
}