.button{
    margin-right:8px;
    background-color: #E2AA01;
    border: 0;
}

.buttonCancel{
    background-color: #F4F4F4;
    color: #727272;
    border: 0;
}

.buttonDelete{
    background-color: #FFF;
    color: #d43a3a;
    border: 0;
}