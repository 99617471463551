.general {
  margin: 10px auto 35px auto;
  background-color: #fff;
  border: 1px solid #eaeaea;
  padding:15px;
}
.title {
  color: var(--black);
  font-size: 32px;
  font-weight: 600;
  margin-top:10px;
}
.subtitle {
  color: var(--grey-3);
  font-weight: 400;
}

/* Icon colors */
.primary{
  width:90px;
  height: 90px;
  border-radius: 8px;
  background: var(--primary-light);
  display: flex;
  justify-content: center;
  align-items: center;
}
.primary-img{
  width:45px;
  filter: invert(90%) sepia(13%) saturate(4069%) hue-rotate(333deg) brightness(101%) contrast(94%);
}

.secondary{
  width:90px;
  height: 90px;
  border-radius: 8px;
  background: var(--secondary-light);
  display: flex;
  justify-content: center;
  align-items: center;
}
.secondary-img{
  width:45px;
  filter: invert(41%) sepia(100%) saturate(1577%) hue-rotate(175deg) brightness(105%) contrast(104%);
}

.tertiary{
  width:90px;
  height: 90px;
  border-radius: 8px;
  background: var(--tertiary-light);
  display: flex;
  justify-content: center;
  align-items: center;
}
.tertiary-img{
  width:45px;
  filter: invert(48%) sepia(42%) saturate(7226%) hue-rotate(250deg) brightness(94%) contrast(86%);
}

.error{
  width:90px;
  height: 90px;
  border-radius: 8px;
  background: var(--warning-light);
  display: flex;
  justify-content: center;
  align-items: center;
}
.error-img{
  width:45px;
  filter: invert(36%) sepia(27%) saturate(6761%) hue-rotate(345deg) brightness(92%) contrast(93%);
}
.success{
  width:90px;
  height: 90px;
  border-radius: 8px;
  background: var(--green-light);
  display: flex;
  justify-content: center;
  align-items: center;
}
.success-img{
  width:45px;
  filter: invert(76%) sepia(8%) saturate(3690%) hue-rotate(77deg) brightness(80%) contrast(97%);
}