.loginContainer {
  background: var(--primary);
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.loginRow {
  width: 25%;
}
.loginLogoImage {
  max-width: 40%;
  margin-bottom: 16px;
}

@media (max-width:992px) {
  .loginRow {
    width: 95%;
  }
}