/* Back Button */
.backButtonContainer{
    display:flex; 
    margin-bottom:0px;
}
.backButton{
    font-weight: 500;
    margin-left: 8px;
    cursor:pointer;
}

/* General */
.orderContainer{
    margin: 25px auto 35px auto;
    background-color: #fff;
    border: 1px solid #eaeaea;
    padding:35px;
    border-radius: 8px;
}
.orderBodySection{
    padding: 28px 0px;
    border-bottom: 1px solid #eaeaea;
    display: flex;
}
.orderHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Order Status */
.orderStatus{
    display:flex;
    align-items: center;
    cursor:pointer;
    position:relative;
}
.orderStatus p{
    display:flex;
    align-items: center;
    padding:12px 25px;
    border-radius:4px;
    margin-right:12px;
}
.status-2{
    background-color:var(--primary-light);
    color:var(--primary-dark);
}
.status-3{
    background-color:var(--secondary-light);
    color:var(--secondary);
}
.status-4{
    background-color:var(--tertiary-light);
    color:var(--tertiary);
}
.status-5{
    background-color:var(--success-light);
    color:var(--success-dark);
}
.status-1{
    background-color:var(--warning-light);
    color:var(--warning-dark);
}
.orderStatusDropdown{
    position:absolute;
    bottom:-208px;
    right:0;
    width:100%;
    z-index: 1000;
    background-color: white;
    border: 1px solid #eaeaea;
    border-radius:4px;
}
.orderStatusDropdown p{
    background-color: white;
    padding:12px;
    border-bottom: 1px solid #eaeaea;
    border-radius:0px;
    margin:0;
}
.orderStatusDropdown p:hover{
    background-color: var(--gray-6);
    border-radius:0px;
}
.orderStatusDropdown p:last-of-type{
    border-bottom: 0px;
}

/* Icon Styles - General */
.orderSectionIcon{
    margin-right:16px;
    height:45px;
    width:45px;
    border-radius:4px;
    background-color:var(--primary-light);
    display: flex;
    justify-content: center;
    align-items: center;
}
.orderSectionIcon img{
    height:24px;
    width:24px;
}

/* Icon Styles - Colors */
.primary{
    background-color:var(--primary-light);
    color:var(--primary-dark);
}
.primary img{
    filter: invert(90%) sepia(13%) saturate(4069%) hue-rotate(333deg) brightness(101%) contrast(94%);
}
.secondary{
    background-color:var(--secondary-light);
    color:var(--secondary-dark);
}
.secondary img{
    filter: invert(41%) sepia(100%) saturate(1577%) hue-rotate(175deg) brightness(105%) contrast(104%);
}
.tertiary{
    background-color:var(--tertiary-light);
    color:var(--tertiary-dark);
}
.tertiary img{
    filter: invert(48%) sepia(42%) saturate(7226%) hue-rotate(250deg) brightness(94%) contrast(86%);
}


/* Order content */
.orderSectionContent p{
    padding:4px 0px;
}
.orderSectionContent input{
    padding: 12px 16px;
    background: #F4F4F4;
    border-radius: 8px;
    border:0px;
    width:100%;
}