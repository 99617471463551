.isActive {
  background-color: transparent;
  color: var(--warning);
  border: none;
}
.isActive:hover {
  background-color: var(--warning-light);
  color: var(--warning);
  border: none;
}
.isSuspended {
  background-color: transparent;
  color: var(--secondary);
  border: none;
}
.isSuspended:hover {
  background-color: var(--secondary-light);
  color: var(--secondary);
  border: none;
}
.downloadQr{
  border-radius: 20px;
  background-color: #e2aa01;
  color: #1b1b1b;
  border: none;
  margin-left: 12px;
}
.downloadQr:hover {
  filter: brightness(1.1);
  background-color: #e2aa01 !important;
  color: #1b1b1b;
}
.downloadRuf{
  border-radius: 20px;
  background-color:#00a8ff;
  color: white;
  border: none;
  margin-left: 12px;
}
.downloadRuf:hover {
  filter: brightness(1.1);
  background-color: #00a8ff;
}

tr:hover {
  background-color: rgba(250, 250, 250, 0.1) !important
}
.img{
  width: 60px;
  aspect-ratio: 1/1;
  object-fit: cover;
}

.userPetDropdown{
  background-color: white;
  border-radius: 8px;
  padding: 12px;
  margin-top: 10px;
  width: fit-content;
  border: 1px solid #eaeaea;
}
.userPetDropdown p{
  padding: 12px 0px;
  text-align: left;
  border-bottom: 1px solid #eaeaea;
  cursor:pointer;
}
.userPetDropdown p:last-of-type{
  border-bottom: 0px;
  padding-bottom:0;
}
.userPetDropdown p:first-of-type{
  padding-top:0;
}


/* Status */
.statusContainer{
  display:flex;
  align-items: center;
  justify-content: center;
  display: inline-block;
  padding:10px 20px;
  border-radius:4px;
  margin-right:12px;
}
.status-2{
  background-color:var(--primary-light);
  color:var(--primary-dark);
}
.status-3{
  background-color:var(--secondary-light);
  color:var(--secondary);
}
.status-4{
  background-color:var(--tertiary-light);
  color:var(--tertiary);
}
.status-5{
    background-color:var(--success-light);
    color:var(--success);
}
.status-1{
  background-color:var(--warning-light);
  color:var(--warning-dark);
}


.shelter-status-1{
  background-color:var(--primary-light) !important;
  color:var(--primary-dark) !important;
}
.shelter-status-2{
  background-color:var(--success-light) !important;
  color:var(--success) !important;
}
.shelter-status-3{
  background-color:var(--warning-light) !important;
  color:var(--warning-dark) !important;
}