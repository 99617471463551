.formContainer{
    display: flex;
}
.formContainer input{
    margin-right: 12px;
}
.formContainer button{
    background-color:#5CB566 ;
    color:white;
}


@media (max-width: 576px){
    .formContainer{
        flex-direction: column;
        margin-bottom: 24px;
    }
    .formContainer input,
    .formContainer button{
        margin: 8px 0px;
    }
}