.container {
    background-color: #fff;
    border: 1px solid #eaeaea;
    padding:15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    margin-top:12px;
  }
.title {
    color: var(--black);
    font-size: 14px;
    font-weight: 500;
    margin:12px 0px;
    text-align: center;
}
.subtitle {
    color: var(--grey-3);
    font-weight: 400;
}
.imgContainer{
    background-color: var(--tertiary-light);
    border-radius: 50%;
    height: 45px;
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.imgContainer img{
    filter: invert(48%) sepia(42%) saturate(7226%) hue-rotate(250deg) brightness(94%) contrast(86%);
    width: 24px;
}

.infoContainer{
    display: flex;
    align-items: flex-start;
    margin-bottom: 8px;
}
.infoContainer img{
    width: 12px;
    margin-right:4px;
}
.infoContainer p{
    font-size: 12px;
    padding:0;
    margin:0;
    line-height: 14px;
    text-align: center;
}