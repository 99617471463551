.orderHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.productsContainer{
    margin-top: 50px;
}

.orderContainer{
    margin-bottom:20px;
}

.button{
    margin-right:8px;
    background-color: #E2AA01;
    border: 0;
}

.addButton{
    color: var(--secondary);
    font-weight: 500;
    cursor: pointer;
}

.buttonCancel{
    background-color: #F4F4F4;
    color: #727272;
    border: 0;
}

.buttonDelete{
    background-color: #FFF;
    color: #d43a3a !important;
    border: 0;
}

.label{
    margin-bottom: 0.5rem;
    color: var(--cui-form-label-color,);
}
.small{
    margin-top: 0.25rem;
    font-size: .875em;
    color: var(--cui-form-text-color,rgba(44,56,74,.38));
}

.imgPreview img{
    width: 200px;
    aspect-ratio: 1/1;
    height:auto;
    object-fit: cover;
    margin: 12px 0px 24px 0px;
}