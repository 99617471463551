.chartContainer{
    margin: 10px 12px 15px 12px !important;
    background-color: #fff;
    border: 1px solid #eaeaea;
    padding:10px 25px 25px 25px;
    border-radius: 8px;
    height:100%;
}

.orderContainer{
    border-right: 1px solid #eaeaea;
    padding:25px;
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
}
.orderContainer:last-of-type{
    border-right: 0px;
}
.iconContainer{
    width:90px;
    height: 90px;
    border-radius: 8px;
    background: var(--secondary-light);
    display: flex;
    justify-content: center;
    align-items: center;
}
.iconContainer img{
    width:45;
    height:45px;
}
.orderContainer h2{
    color: var(--black);
    font-size: 40px;
    font-weight: 600;
    margin:20px 0px;
}
.orderContainer p{
    color: var(--gray-3);
    text-align: center;
}

/* Icon colors */
.primary{
    background: var(--primary-light);
  }
  .primary-img{
    filter: invert(90%) sepia(13%) saturate(4069%) hue-rotate(333deg) brightness(101%) contrast(94%);
  }
  
  .secondary{
    background: var(--secondary-light);
  }
  .secondary-img{
    filter: invert(41%) sepia(100%) saturate(1577%) hue-rotate(175deg) brightness(105%) contrast(104%);
  }
  
  .tertiary{
    background: var(--tertiary-light);
  }
  .tertiary-img{
    filter: invert(48%) sepia(42%) saturate(7226%) hue-rotate(250deg) brightness(94%) contrast(86%);
  }