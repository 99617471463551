.outletContainer{
    width: calc(100% - 256px);
    margin-left: 256px;
}

@media (max-width: 576px) {
    .outletContainer{
        width: 100%;
        margin-left: unset;
    }
    
}