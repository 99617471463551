.orderHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.button{
    margin-right:8px;
    background-color: #E2AA01;
    border: 0;
}

.productsContainer{
    margin-top: 50px;
}